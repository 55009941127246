import { Box, Stack, Typography, useTheme } from '@mui/material'
import { IconSvg } from '@graphcommerce/next-ui'
import * as Icons from '@graphcommerce/next-ui/icons'
import { Block } from '../Block'
import ContainerAdapter from '../../../Layout/ContainerAdapter'
import PlusIcon from '../../Icons/Plus/icon.svg'
import RefreshIcon from '../../Icons/Refresh/icon.svg'
import PhoneIcon from '../../Icons/Phone/icon.svg'

type IconTitleColumnsItem = {
  title?: {
    icon?: string
    text?: string
  }
  content?: string
}

export type IconTitleColumns = {
  title?: string
  backgroundColor?: string
  items: IconTitleColumnsItem[]
}

export const IconTitleColumns = (props: IconTitleColumns) => {
  const theme = useTheme()
  const { title, backgroundColor = theme.palette.primary.contrastText, items } = props

  const itemIcon = (key: string) => {
    switch (key) {
      case 'PlusIcon':
        return PlusIcon
      case 'PhoneIcon':
        return PhoneIcon
      case 'RefreshIcon':
        return RefreshIcon
      default:
        return Icons[key]
    }
  }

  return (
    <Block
      className='icon-title-columns-layout how-to-buy-filter-section'
      heading={
        <ContainerAdapter>
          <Typography
            className='section-title'
            sx={{ textAlign: { sm: 'left', md: 'center' } }}
            variant='h2'
          >
            {title}
          </Typography>
        </ContainerAdapter>
      }
      content={
        <ContainerAdapter>
          <Stack
            className='box-items-inner'
            direction={{ sm: 'column', md: 'row' }}
            justifyContent='center'
            alignItems='stretch'
            spacing={2}
            sx={(theme) => ({
              textAlign: { sm: 'left', md: 'center' },
              marginTop: theme.spacing(2),
            })}
          >
            {items.map((item, index) => {
              return (
                <Box
                  className='box-item'
                  key={index}
                  sx={(theme) => ({
                    backgroundColor: '#fff',
                    padding: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                    borderRadius: theme.spacing(1),
                    textAlign: 'left',
                  })}
                >
                  <Stack className='box-content-top' direction='row' spacing={2}>
                    <div className='icon-inner'>
                      <IconSvg
                        sx={{ width: '24px', height: '24px', color: '#000' }}
                        className='box-icon'
                        src={itemIcon(item?.title?.icon || 'iconPlus')}
                      />
                    </div>
                    <Typography className='box-title' variant='body1'>
                      <strong>{item?.title?.text}</strong>
                    </Typography>
                  </Stack>
                  <Typography className='box-content' variant='body1'>
                    {item?.content}
                  </Typography>
                </Box>
              )
            })}
          </Stack>
        </ContainerAdapter>
      }
      sx={{ backgroundColor: backgroundColor }}
    />
  )
}
