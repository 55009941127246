import { Box, Button, Container, ContainerProps, Typography } from '@mui/material'
import { Block } from '../../Block'
import { useQuery } from '@graphcommerce/graphql'
import ButtonFilter from '../../../Button/ButtonFilter'
import { SearchFiltersDocument, SearchFiltersQuery } from './SearchFilters.gql'
import { IconSvg } from '@graphcommerce/next-ui'
import ChevronRightIcon from '../../../Icons/ChevronRight/chevron-right-icon.svg'
import router from 'next/router'

type Item = NonNullable<NonNullable<NonNullable<SearchFiltersQuery['menu']>['items']>[0]>

export type SearchFiltersType = {
  title?: string
  col?: string
  button_submit?: string
  cat_fixed?: string
} & Omit<ContainerProps, 'children'>

const aliasCats = [
  { alias: 'Villavent', clone: 'Systemair' },
  // Add more alias mappings here if needed
]

const handleAliasCategories = (categories: any) => {
  aliasCats.forEach(({ alias, clone }) => {
    const originalCategory = categories.find((item) => item?.name === clone)
    if (originalCategory) {
      const clonedCategory = { ...originalCategory, name: alias }
      categories.push(clonedCategory)
    }
  })
  return categories
}

const handleFixedCategories = (categories: any, fixedCategories: string[], maxItems: number) => {
  const orderedCategories = fixedCategories
    .map((cat) => categories.find((item) => item?.name === cat))
    .filter((item) => item) as Item[]

  const remainingCategories = categories.filter(
    (item) => !fixedCategories.includes(item?.name ?? ''),
  )

  return [...orderedCategories, ...remainingCategories].slice(0, maxItems)
}

export function SearchFilters(props: SearchFiltersType) {
  const { title, col, button_submit, cat_fixed } = props

  const maxItems = 6
  const fixedCategories = cat_fixed?.split(',').map((cat) => cat.trim()) || []

  let rootPath = ''
  const listLevel1: Item[] = []
  const { loading, data } = useQuery<SearchFiltersQuery>(SearchFiltersDocument)

  if (!loading && data) {
    let allCategories =
      data.menu?.items?.flatMap((item, index) => {
        if (index === 0) rootPath = item?.uid ?? ''
        return item?.children || []
      }) || []

    allCategories = handleAliasCategories(allCategories)
    const finalCategories = handleFixedCategories(allCategories, fixedCategories, maxItems)

    finalCategories.forEach((item) => {
      if (item) listLevel1.push(item)
    })
  }

  const handleClick = (e: any, level: string) => {
    e.preventDefault()
    const handleElement = document.getElementById('handleOpenMenu')
    if (handleElement) {
      router.push(`#single-filter::${level}`)
      handleElement.setAttribute('paths', level)
      handleElement.click()
    }
  }
  return (
    <Block
      className='search-filter-section'
      sx={{ backgroundColor: '#F9F9F9' }}
      heading={
        <Container
          className='section-title-inner'
          maxWidth='md'
          sx={{ textAlign: { sm: 'left', md: 'center' } }}
        >
          <Typography className='section-title' variant='h2'>
            {title}
          </Typography>
        </Container>
      }
      content={
        <>
          <Container maxWidth='md' className='content-wrap'>
            <Box
              className='content-inner'
              sx={() => ({
                paddingTop: '1rem',
                paddingBottom: '1.5rem',
                display: 'grid',
                columnGap: {
                  xs: 3,
                  md: '24px',
                },
                rowGap: {
                  xs: 2,
                  md: '24px',
                },
                gridTemplateColumns: {
                  xs: `repeat(2, 1fr)`,
                  md: `repeat(${col ?? 3}, 1fr)`,
                },
                maxWidth: '684px',
                margin: '0 auto',
              })}
            >
              {listLevel1.map((item, index) => (
                <ButtonFilter
                  className='cat-item'
                  key={index}
                  onClick={(event) => handleClick(event, rootPath + ',' + item.uid)}
                >
                  {item.name ?? ''}
                </ButtonFilter>
              ))}
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                className='see-more-btn'
                //size={'undefined'}
                color='primary'
                onClick={(event) => handleClick(event, rootPath)}
                endIcon={
                  <IconSvg
                    sx={{ fontSize: '24px!important' }}
                    src={ChevronRightIcon}
                    size={'inherit'}
                  />
                }
                sx={(theme) => ({
                  [theme.breakpoints.down('md')]: {
                    gap: '5px',
                    width: '100%',
                    height: '48px',
                    padding: '8px 8px 8px 16px',
                    lineHeight: 'normal',
                    letterSpacing: '0.5px',
                    fontSize: '18px!important',
                  },
                })}
              >
                {button_submit ?? 'Submit'}
              </Button>
            </Box>
          </Container>
        </>
      }
    />
  )
}
