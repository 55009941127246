import { Button, ButtonProps } from '@graphcommerce/next-ui'
import { styled } from '@mui/material/styles'
import { IconSvg } from '@graphcommerce/next-ui'
import ChevronRightIcon from '../Icons/ChevronRight/chevron-right-icon.svg'

const ButtonFilterStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.filtoria.grey.darkest,
  borderWidth: '1px',
  borderColor: theme.palette.filtoria.grey.darkest,
  fontWeight: 400,
  fontSize: '16px !important',
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.filtoria.grey.darkest,
  },
}))

const ButtonFilter = (props: ButtonProps) => {
  return (
    <ButtonFilterStyled
      variant='outlined'
      size='medium'
      sx={(theme) => ({
        justifyContent: 'space-between',
        paddingTop: theme.spacings.xxs,
        paddingBottom: theme.spacings.xxs,
        borderColor: theme.palette.filtoria.grey.darkest + '',
        borderRadius: '4px',
        '& .MuiButton-endIcon': { marginLeft: 'auto' },
      })}
      endIcon={
        <IconSvg
          src={ChevronRightIcon}
          size='inherit'
          sx={(theme) => ({
            color: theme.palette.filtoria.primary.medium,
            fontSize: '24px!important',
          })}
        />
      }
      {...props}
    >
      {props.children}
    </ButtonFilterStyled>
  )
}

export default ButtonFilter
