import { RowShortcodeFragment } from './RowShortcode.gql'
import { SearchFilters } from '../../Common/Blocks/Shortcode/SearchFilters/SearchFilters'
import { Contacts } from '../../Common/Blocks/Shortcode/Contacts'
import { IconTitleColumns } from '../../Common/Blocks/Shortcode/IconTitleColumns'

export function RowShortcode(props: RowShortcodeFragment) {
  const { shortcodeTitle, shortcodeType, shortcodeConfig } = props

  const components = {
    SearchFilters: SearchFilters,
    Contacts: Contacts,
    IconTitleColumns: IconTitleColumns,
  }
  const RealComponent = components[shortcodeType] ?? components['Contacts']
  return <RealComponent title={shortcodeTitle} {...shortcodeConfig} />
}
