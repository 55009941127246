import React from 'react'

export const component = 'ProductReviewChip'
export const exported =
  '@graphcommerce/magento-review/components/ProductReviewChip/ProductReviewChip'

const DisableProductReviewChip = () => {
  return <React.Fragment />
}

export const Plugin = DisableProductReviewChip
