import { ButtonLinkListItem, ButtonLinkList } from '@graphcommerce/next-ui'
import { RowButtonLinkListFragment } from './RowButtonLinkList.gql'
import { Box } from '@mui/material'

export type RowButtonLinkListProps = RowButtonLinkListFragment

/** @deprecated Replace with RowLinks */
export function RowButtonLinkList(props: RowButtonLinkListProps) {
  const { title, links, identity } = props

  const isBig = links.some((link) => (link.title?.length ?? 0) > 30)
  return (
    <Box className={`row-button-link-list ${identity || ''} `}>
      <ButtonLinkList title={title} containsBigLinks={isBig} component='h2'>
        {links?.map((link) => (
          <ButtonLinkListItem className='button-link-item' key={link.url} url={`/${link.url}`}>
            {link.title}
          </ButtonLinkListItem>
        ))}
      </ButtonLinkList>
    </Box>
  )
}
