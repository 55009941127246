import { RichText, RichTextProps } from '@graphcommerce/graphcms-ui/components/RichText'
import { Box, Button, Link, Typography } from '@mui/material'
import { IconSvg } from '@graphcommerce/next-ui'
import iconChevronRight from '../Icons/ChevronRight/chevron-right-icon.svg'
import { Image } from '@graphcommerce/image'
import { getHygraphImageUrl } from '../../../utils'

export function RichTextCustom(props: RichTextProps) {
  return (
    <RichText
      renderers={{
        link: (props) => {
          const { href, openInNewTab, ...containerProps } = props
          const attr: any = props
          const className = attr?.className ?? ''
          if (className) {
            const classNameAttr = className.split('-')

            let endIcon: any = null

            //flag to add arrow icon: https://netpower.atlassian.net/browse/FL-233
            if (classNameAttr.some((cl) => cl.includes('btn_add_right_arrow'))) {
              endIcon = (
                <IconSvg
                  src={iconChevronRight}
                  size='inherit'
                  sx={(theme) => ({
                    color: theme.palette.filtoria.primary.dark,
                    fontSize: '24px!important',
                  })}
                />
              )
            }

            if (classNameAttr[0] == 'button') {
              return (
                <Button
                  className='btn'
                  variant='pill'
                  color={classNameAttr[1]}
                  size={classNameAttr[2]}
                  {...containerProps}
                  endIcon={endIcon}
                />
              )
            }
          }
          return (
            <Link
              href={href}
              underline='hover'
              {...containerProps}
              target={openInNewTab ? '_blank' : undefined}
            />
          )
        },
        paragraph: (props) => (
          <Typography className='description-text' variant='body1' gutterBottom {...props} />
        ),
        class: (props: any) => <Box className={props.className}>{props.children}</Box>,
        image: (props: any) => (
          <div className={'image-wrap'}>
            <Image
              src={props.src}
              loader={({ src, width, quality }) => {
                //https://netpower.atlassian.net/browse/FL-228
                //set quality from 52 -> 100
                // return `${src}?w=${width}&q=${100}&fm=webp`
                return getHygraphImageUrl(src)
              }}
              height={props.height}
              width={props.width}
              alt={props.title}
            />
          </div>
        ),
      }}
      {...props}
    />
  )
}
