import React from 'react'
import { Box, BoxProps } from '@mui/material'

export type BlockProps = {
  heading?: React.ReactNode
  content?: React.ReactNode
} & Omit<BoxProps, 'children'>

export function Block(props: BlockProps) {
  const { heading, content, sx = [], ...containerProps } = props

  return (
    <Box
      sx={[
        (theme) => ({
          paddingTop: theme.spacing(3.5),
          paddingBottom: theme.spacing(3.5),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...containerProps}
    >
      {heading}
      {content}
    </Box>
  )
}
