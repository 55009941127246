import { Box, Button, Card, CardProps, Typography } from '@mui/material'
import { IconSvg } from '@graphcommerce/next-ui'
import CardContent from '@mui/material/CardContent'
// import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { Trans } from '@lingui/react'
import iconChevronRight from '../Icons/ChevronRight/chevron-right-icon.svg'
import Image from 'next/image'

export type CardPostType = {
  title?: string
  url?: string
  description?: any
  thumb?: string
  button?: boolean
  readmoretext?: string
} & Omit<CardProps, 'children'>

export function CardPost(props: CardPostType) {
  const { title, url, description, thumb, button, readmoretext, sx = [], ...containerProps } = props

  const LinkText = () => <Trans id={readmoretext ?? 'Les mer'} />

  return (
    <Card
      className='card-post'
      sx={[
        {
          borderRadius: {
            xs: '8px',
            md: 0,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...containerProps}
    >
      <div className='card-media'>
        {thumb && (
          <Image
            style={{
              objectFit: 'cover',
            }}
            src={thumb}
            alt={'Filtoria'}
            width={576}
            height={327}
            quality={100}
          />
        )}
      </div>
      <CardContent className='card-content'>
        <Typography
          variant='h3'
          sx={(theme) => ({
            color: theme.palette.filtoria.primary.dark,
            marginBottom: theme.spacings.xxs,
          })}
          className='cardpost-content-title'
        >
          {title}
        </Typography>
        <div className='cardpost-content-desciption'>{description}</div>

        <Box className='btn' sx={(theme) => ({ marginTop: '1rem' })}>
          {button ? (
            <Button
              className='item-link'
              href={url}
              color='secondary'
              endIcon={
                <IconSvg
                  sx={{ fontSize: '24px!important' }}
                  size='inherit'
                  src={iconChevronRight}
                />
              }
              size='large'
            >
              <LinkText />
            </Button>
          ) : (
            <Link
              className='item-link'
              href={url}
              underline='none'
              sx={(theme) => ({
                color: theme.palette.filtoria.grey.darkest,
                paddingBottom: '6px',
                borderBottom: 'solid 2px',
                borderBottomColor: theme.palette.filtoria.secondary.dark,
              })}
            >
              <LinkText />
            </Link>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
