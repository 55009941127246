import { Block } from '../Block'
import { Button, Container, Stack, Typography } from '@mui/material'
import { IconSvg, iconCheckmark } from '@graphcommerce/next-ui'

const CheckIcon = () => (
  <IconSvg
    src={iconCheckmark}
    size='large'
    sx={(theme) => ({ color: theme.palette.filtoria.secondary.dark })}
  />
)

type ContactsButtonType = {
  url: string
  color: string
  title: string
}

type ContactsType = {
  title?: string
  texts?: string[]
  buttons?: ContactsButtonType[]
}

export function Contacts(props: ContactsType) {
  const { title, texts = [], buttons = [] } = props

  return (
    <Block
      heading={
        <Container maxWidth='md' sx={{ textAlign: { sm: 'left', md: 'center' } }}>
          <Typography variant='h2'>{title}</Typography>
        </Container>
      }
      content={
        <>
          <Container
            maxWidth='md'
            sx={(theme) => ({ padding: theme.spacings.xs, overflow: 'hidden' })}
          >
            <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
              {buttons.map((item, index) => {
                return (
                  <Button
                    color={item.color as 'primary'}
                    key={index}
                    size='medium'
                    sx={{ whiteSpace: 'nowrap' }}
                    href={item.url}
                  >
                    {item.title}
                  </Button>
                )
              })}
            </Stack>
          </Container>
          <Container maxWidth='md' sx={(theme) => ({ padding: theme.spacings.xs })}>
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              spacing={4}
              justifyContent='center'
              alignItems='center'
            >
              {texts.map((item, index) => {
                return (
                  <Stack
                    key={index}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={3}
                  >
                    <CheckIcon />
                    <Typography variant='body1'>{item}</Typography>
                  </Stack>
                )
              })}
            </Stack>
          </Container>
        </>
      }
      sx={(theme) => ({ backgroundColor: theme.palette.filtoria.primary.bg })}
    />
  )
}
