import { useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Image } from '@graphcommerce/image'
import { RichTextCustom } from '../RichTextCustom/Index'
import ContainerAdapter from '../../Layout/ContainerAdapter'

export type BannerPostType = {
  imageUrl: string
  copy: any
  backgroundColor?: string
}

export function BannerPost(props: BannerPostType) {
  const { imageUrl, copy, backgroundColor } = props
  const theme = useTheme()

  return (
    <div
      className='banner-post-section two-column-layout'
      style={{
        backgroundColor: backgroundColor ?? theme.palette.filtoria.primary.dark,
      }}
    >
      <ContainerAdapter>
        <Grid container columnSpacing={{ md: 7 }}>
          <Grid
            columnGap={'64px'}
            className='col-right text-col'
            md={5}
            sm={12}
            sx={() => ({
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
              alignItems: 'left',
              padding: '24px 16px 8px 24px',
            })}
          >
            {copy ? (
              <RichTextCustom
                {...copy}
                sxRenderer={{
                  all: (theme) => ({
                    '& p:last-child': {
                      marginBottom: 0,
                    },
                    '& .MuiButtonBase-root': {
                      [theme.breakpoints.down('md')]: {
                        width: '100%',
                      },
                    },
                  }),
                  paragraph: () => ({
                    color: '#1A1A1B',
                    textAlign: 'left',
                    // fontSize: '14px',
                  }),
                  'heading-one': () => ({ color: '#1A1A1B' }),
                  'heading-two': () => ({ color: '#1A1A1B' }),
                }}
              />
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            className='col-left image-col'
            md={7}
            sm={12}
            sx={() => ({
              display: {
                xs: 'none',
                md: 'block',
              },
            })}
          >
            <Image
              src={imageUrl}
              layout='fill'
              alt=''
              sx={{ objectFit: 'cover', height: '350px' }}
            />
          </Grid>
        </Grid>
      </ContainerAdapter>
    </div>
  )
}
