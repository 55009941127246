import { Container, Stack, Typography } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'
import { Block } from '../../../Common/Blocks/Block'
import { CardPost } from '../../../Common/Card/CardPost'
import { RichTextCustom } from '../../../Common/RichTextCustom/Index'
// import Heading2 from '../../../Common/Heading/Heading2'
import { responsiveVal } from '@graphcommerce/next-ui'
import ContainerAdapter from '../../../Layout/ContainerAdapter'

export function ThreeColunms(props: RowLinksFragment) {
  const { title, pageLinks } = props

  return (
    <Block
      className={`three-colunms-layout article-section`}
      heading={
        <Container maxWidth='md' sx={{ textAlign: { sm: 'left', md: 'center' } }}>
          <Typography variant='h2'>{title}</Typography>
        </Container>
      }
      content={
        <ContainerAdapter>
          <Stack
            direction={{ sm: 'column', md: 'column' }}
            justifyContent='space-between'
            className='content-wrap'
            sx={{
              overflow: 'auto',
            }}
          >
            {pageLinks.map((pageLink) => {
              const cardProps = {
                title: pageLink.title,
                url: pageLink.url,
                description: pageLink?.description && <RichTextCustom {...pageLink.description} />,
                thumb: pageLink.asset?.url,
              }
              return (
                <CardPost
                  className='article-item'
                  key={pageLink.id}
                  {...cardProps}
                  sx={(theme) => ({
                    backgroundColor: '#F9F9FA',
                    minWidth: responsiveVal(271, 290),
                    // marginBottom: theme.spacing(3),
                    '& .cardpost-content-title': {
                      color: theme.palette.filtoria.grey.darkest + ' !important',
                    },
                  })}
                  button={true}
                  readmoretext={'Les mer'}
                />
              )
            })}
          </Stack>
        </ContainerAdapter>
      }
    />
  )
}
