import { Box, Card, CardContent, SxProps, Theme, useTheme } from '@mui/material'
import { isMobile } from '../../../utils'
import { RichTextCustom } from '../RichTextCustom/Index'
import Image from 'next/image'
import { useEffect, useState } from "react";

export type BannerImage = {
  topic?: string | null
  imageUrl: string
  copy: any
  backgroundColor?: string
  identity?: string
}

export function BannerImage(props: BannerImage) {
  const [mobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (isMobile()) {
      setIsMobile(true)
    }
  }, [])
  const { imageUrl, copy, backgroundColor } = props
  const theme = useTheme()
  const bgColor = backgroundColor ?? theme.palette?.filtoria.primary.lightest

  // let backgroundImageUrl = imageUrl

  // if (isMobile()) {
  //   backgroundImageUrl = getHygraphImageUrl(imageUrl, 656, 400)
  // }

  const contentWithoutImageCss: SxProps<Theme> = () => ({
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.primary.contrastText,
  })

  return (
    <Box
      className={
        'banner-text-on-image home-slider ' + (imageUrl ? 'slider-image-text' : 'slider-only-text')
      }
      sx={{
        backgroundColor: {
          xs: 'transparent',
          md: backgroundColor,
        },
      }}
    >
      <Card
        className={'slider-wrapper ' + (imageUrl ? 'slider-image-text' : 'slider-only-text')}
        sx={{
          position: 'relative',
          height: '90%',
          boxSizing: 'border-box',
          backgroundColor: bgColor,
          borderRadius: 0,
          [theme.breakpoints.down('md')]: {
            margin: '0',
            borderRadius: '8px',
          },
        }}
      >
        <div className='image-box-content'>
          <Image
            priority={true}
            src={imageUrl}
            alt={'Filtoria'}
            width={mobile ? 350 : 960}
            height={mobile ? 200 : 450}
            quality={100}
          />
        </div>
        <CardContent
          className='text-box-content'
          sx={
            imageUrl
              ? {
                  backgroundColor: bgColor,
                }
              : contentWithoutImageCss
          }
        >
          {copy ? (
            <RichTextCustom
              {...copy}
              sxRenderer={{
                // all: { contentWithoutImageCss },
                'heading-one': (theme) => ({
                  filter: 'contrast(100%)',
                  [theme.breakpoints.down('md')]: { fontSize: '24px' },
                }),
                'heading-two': (theme) => ({
                  filter: 'contrast(100%)',
                  [theme.breakpoints.down('md')]: { fontSize: '20px' },
                }),
              }}
            />
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
