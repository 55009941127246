import {
  Scroller,
  // ScrollerButton,
  ScrollerDots,
  ScrollerProvider,
} from '@graphcommerce/framer-scroller'
import { RowSlideBannerFragment } from './RowSlideBanner.gql'
import { Box } from '@mui/material'
import {
  // IconSvg,
  extendableComponent,
  // iconChevronLeft,
  // iconChevronRight,
  // useFabSize,
} from '@graphcommerce/next-ui'
import { RowSpecialBanner } from '../RowSpecialBanner/RowSpecialBanner'

const compName = 'RowLinks' as const
const parts = [
  'root',
  'scroller',
  'title',
  'copy',
  'swipperButton',
  'centerRight',
  'centerLeft',
] as const
const { classes } = extendableComponent(compName, parts)

type RowSlildeBannerProps = RowSlideBannerFragment

export const RowSlideBanner = (props: RowSlildeBannerProps) => {
  const { slideIdentity, rowSpecialBanners } = props
  // const fabSize = useFabSize('responsive')
  return (
    <Box className={'slide-banner-' + slideIdentity} sx={{ position: 'relative' }}>
      <ScrollerProvider scrollSnapAlign='end'>
        <Scroller
          className={classes.scroller + ' scroller-inner'}
          hideScrollbar
          sx={() => ({
            '& .banner-text-on-image': {
              width: {
                md: '100vw',
                xs: 'calc(100vw - 32px)',
              },
            },
          })}
        >
          {rowSpecialBanners.map((item, index) => (
            <RowSpecialBanner key={index} {...item} />
          ))}
        </Scroller>
        {/*
        <Box
          className={classes.centerLeft}
          sx={(theme) => ({
            display: 'grid',
            gridAutoFlow: 'row',
            gap: theme.spacings.xxs,
            zIndex: 999,
            position: 'absolute',
            left: theme.spacings.sm,
            top: `calc(50% - calc(${fabSize} / 2))`,
          })}
        >
          <ScrollerButton
            direction='left'
            className={classes.swipperButton}
            sx={{
              display: 'flex',
              zIndex: 'inherit',
            }}
            size='responsive'
          >
            <IconSvg src={iconChevronLeft} />
          </ScrollerButton>
        </Box>
        <Box
          className={classes.centerRight}
          sx={(theme) => ({
            display: 'grid',
            gap: theme.spacings.xxs,
            zIndex: 999,
            position: 'absolute',
            right: theme.spacings.sm,
            top: `calc(50% - calc(${fabSize} / 2))`,
          })}
        >
          <ScrollerButton
            direction='right'
            className={classes.swipperButton}
            sx={{
              display: 'flex',
            }}
            size='responsive'
          >
            <IconSvg src={iconChevronRight} />
          </ScrollerButton>
        </Box>
        */}
        <Box
          sx={(theme) => ({
            display: 'flex',
            px: theme.spacing(1),
            gap: theme.spacing(1),
            position: 'absolute',
            bottom: theme.spacing(4),
            justifyContent: 'center',
            width: '100%',
            pointerEvents: 'none',
            '& > *': {
              pointerEvents: 'all',
            },
          })}
          className='dots-container'
        >
          <ScrollerDots
            layout='position'
            layoutDependency={true}
            sx={(theme) => ({
              '& > .MuiButtonBase-root': {
                width: theme.spacing(2.5),
                height: theme.spacing(2.5),
                '& .ScrollerDot-circle': {
                  width: '10px',
                  height: '10px',
                  backgroundColor: '#ffffff',
                  opacity: 0.8,
                },
              },
            })}
          />
        </Box>
      </ScrollerProvider>
    </Box>
  )
}
