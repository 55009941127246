import { RichText } from '@graphcommerce/graphcms-ui'
import { responsiveVal, VariantImageLabelSwiper } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { RowLinksFragment } from '../RowLinks.gql'
import { CardPost } from '../../../Common/Card/CardPost'
import { RichTextCustom } from '../../../Common/RichTextCustom/Index'
import ContainerAdapter from '../../../Layout/ContainerAdapter'

export function ImageLabelSwiper(props: RowLinksFragment & { readmoretext?: string }) {
  const { title, rowLinksCopy, pageLinks, readmoretext } = props

  return (
    <Box
      className='image-swiper-section'
      sx={(theme) => ({
        backgroundColor: '#EDEBE9',
        paddingTop: theme.spacing(3.5),
        paddingBottom: theme.spacing(3.5),
      })}
    >
      <ContainerAdapter>
        <VariantImageLabelSwiper
          title={title}
          copy={rowLinksCopy && <RichText {...rowLinksCopy} />}
          maxWidth='xl'
          sx={(theme) => ({
            marginBottom: 0,
            '& .Scroller-root': { alignItems: 'start' },
            '.RowLinks-title': {
              fontSize: '22px',
              textAlign: { sm: 'left', md: 'center', textTransform: 'none' },
            },
            '& .RowLinks-swipperButton .MuiButtonBase-root': {
              backgroundColor: theme.palette.filtoria.primary.dark,
              color: theme.palette.primary.contrastText,
            },
            '& .RowLinks-centerLeft, .RowLinks-centerRight': {
              top: 0,
            },
          })}
        >
          {(pageLinks.length > 3 ? pageLinks.slice(0, 3) : pageLinks).map((pageLink) => (
            <CardPost
              className='card-item'
              key={pageLink.id}
              title={pageLink.title}
              url={pageLink.url}
              description={pageLink?.description && <RichTextCustom {...pageLink.description} />}
              thumb={pageLink.asset?.url}
              sx={(theme) => ({
                maxWidth: {
                  xs: responsiveVal(271, 290),
                  md: responsiveVal(300, 484),
                },
                '& .cardpost-content-title': { color: theme.palette.filtoria.grey.darkest },
              })}
              button={true}
              readmoretext={readmoretext}
            />
          ))}
        </VariantImageLabelSwiper>
      </ContainerAdapter>
    </Box>
  )
}
