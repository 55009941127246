import { RowLinksFragment } from '../RowLinks.gql'
import { List, IconButton, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
// import React from 'react'
import ChevronRightIcon from '../../../Common/Icons/ChevronRight/index'
import { useRouter } from 'next/router'

export function MenuList(props: RowLinksFragment) {
  // const { pageLinks } = props
  const router = useRouter()

  return (
    <List
      sx={{
        padding: 0,
        marginTop: '-16px',
        width: '100%',
      }}
      component='nav'
    >
      {props.pageLinks.map((_pageLink, index: number) => {
        return (
          <ListItemButton
            key={index}
            alignItems={'center'}
            sx={{
              paddingTop: '8px',
              paddingBottom: '8px',
              borderBottom: '1px solid #D8D6D4',
              top: 0,
              minHeight: '56px',
            }}
            // component='a'
            // href={pageLink.url}
            onClick={() => router.push(_pageLink.url)}
          >
            <ListItemText sx={{ margin: 0 }} primary={_pageLink.title} />
            <ListItemIcon sx={{ margin: 0, justifyContent: 'flex-end' }}>
              <IconButton edge='end' aria-label='comments'>
              <ChevronRightIcon sx={{ color: '#026161', fontSize: '24px!important' }} />
              </IconButton>
            </ListItemIcon>
          </ListItemButton>
        )
      })}
    </List>
  )
}
