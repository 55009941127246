import { RichText } from '@graphcommerce/graphcms-ui'
import { BlogContent } from '@graphcommerce/next-ui'
import { RowBlogContentFragment } from './RowBlogContent.gql'
import { RichTextCustom } from '../Common/RichTextCustom/Index'

type RowBlogContentProps = RowBlogContentFragment

export function RowBlogContent(props: RowBlogContentProps) {
  const { content } = props
  if (!content) return null
  return (
    <div className='post-content paragraph-content'>
      <BlogContent>
        <RichTextCustom {...content} />
      </BlogContent>
    </div>
  )
}
